var AWS_KEY = process.env.VUE_APP_AWS_KEY;
var AWS_SECRET = process.env.VUE_APP_AWS_SECRET;

var AWS = require("aws-sdk");

AWS.config.update({
  accessKeyId: AWS_KEY,
  secretAccessKey: AWS_SECRET,
  region: "us-west-2",
});

let s3 = new AWS.S3();

export default s3;
