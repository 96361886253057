<template>
  <div>
    <div v-if="user">
      <b-button
        type="is-primary"
        @click="logout"
        style="margin:1em auto;position:absolute;right:1em;top:0em;"
      >
        Logout
      </b-button>
    </div>
    <masonry
      :cols="{ default: 4, 1000: 3, 700: 2, 500: 1 }"
      :gutter="{ default: '30px', 700: '15px' }"
      style="padding: 5em 1em 1em;"
    >
      <div v-for="(image, index) in resizedImages" :key="index">
        <div class="is-flex is-justify-content-center" style="margin:1em auto;">
          <video v-if="image.toLowerCase().includes('mov')" controls>
            <source :src="baseUrl + encodeURI(image)" type="video/mp4" />
          </video>
          <img v-else v-lazy="encodeURI(image)" />
        </div>
      </div>
    </masonry>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import s3 from "../services/s3";

export default {
  data: () => ({
    baseUrl: "https://s3-us-west-2.amazonaws.com/files.violetmillar.com/",
    user: firebase.auth().currentUser,
    images: [],
  }),
  computed: {
    resizedImages: function() {
      let resizedUrls = [];
      for (let i = 0; i < this.images.length; i++) {
        const image = this.images[i];
        if (image.Key.toLowerCase().indexOf("mov") === -1) {
          const imageRequest = JSON.stringify({
            bucket: "files.violetmillar.com",
            key: image.Key,
            edits: {
              resize: {
                width: 350,
              },
            },
          });
          const url = `https://d2865tv7jrzslr.cloudfront.net/${btoa(
            imageRequest
          )}`;
          resizedUrls.push(url);
        } else {
          resizedUrls.push(image.Key);
        }
      }
      return resizedUrls;
    },
  },
  methods: {
    gets3Images() {
      const self = this;
      var params = {
        Bucket: "files.violetmillar.com",
      };

      s3.listObjects(params, function(err, data) {
        if (err) throw err;
        self.images = data.Contents;
      });
    },
    logout() {
      sessionStorage.clear();
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({ name: "Login" });
        });
    },
  },
  created() {
    this.gets3Images();
  },
};
</script>
