import Vue from "vue";
import firebase from "firebase/app";
import "firebase/auth";
import Router from "vue-router";
import Login from "../components/Login";
import Home from "../components/Home";
import Admin from "../components/Admin";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        title: "Violet Millar | Login",
      },
    },
    {
      path: "/admin",
      name: "Admin",
      component: Admin,
      meta: {
        title: "Violet Millar | Admin",
        requiresAuth: true,
      },
    },
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        title: "Violet Millar | Home",
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Violet Millar";

  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !currentUser) next("login");
  else if (!requiresAuth && currentUser) next("dashboard");
  else next();
});

export default router;
