import Vue from "vue";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import App from "./App.vue";
import router from "./routes";
import Buefy from "buefy";
import VueMasonry from "vue-masonry-css";
import VueLazyload from "vue-lazyload";
import "buefy/dist/buefy.css";

Vue.config.productionTip = false;

Vue.use(Buefy);
Vue.use(VueMasonry);
Vue.use(VueLazyload);

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

let app = "";
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
