<template>
  <section
    v-if="
      user.email === 'me@zachmillar.com' || user.email === 'zbmillar@gmail.com'
    "
    class="is-flex"
    style="height:100vh;"
  >
    <div
      class="is-justify-content-center is-flex is-flex-direction-column"
      style="margin:auto;"
      v-if="!uploadSuccess"
    >
      <b-field>
        <b-upload v-model="dropFiles" multiple drag-drop>
          <section class="section">
            <div class="content has-text-centered">
              <p>
                <b-icon icon="upload" size="is-large"> </b-icon>
              </p>
              <p>Drop your files here or click to upload</p>
            </div>
          </section>
        </b-upload>
      </b-field>

      <div class="tags" style="max-width: 350px;">
        <span
          v-for="(file, index) in dropFiles"
          :key="index"
          class="tag is-primary"
        >
          {{ file.name }}
          <button
            class="delete is-small"
            type="button"
            @click="deleteDropFile(index)"
          ></button>
        </span>
      </div>
      <b-button
        type="is-primary"
        @click.prevent="uploadToS3()"
        style="margin:1em auto;"
      >
        Upload
      </b-button>
    </div>
    <div
      class="is-justify-content-center is-flex is-flex-direction-column"
      style="margin:auto;"
      v-else
    >
      <h2 class="title is-2">Upload Success</h2>
      <a href="/">Back Home</a>
    </div>
  </section>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import s3 from "../services/s3";

export default {
  data: () => ({
    uploadSuccess: false,
    user: firebase.auth().currentUser,
    dropFiles: [],
  }),
  methods: {
    uploadToS3() {
      const self = this;
      const files = this.dropFiles;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const params = {
          Bucket: "files.violetmillar.com",
          Key: encodeURI(file.name),
          Body: file,
          ACL: "public-read",
        };
        s3.upload(params, function(err) {
          if (err) throw err;
          self.uploadSuccess = true;
        });
      }
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
  },
};
</script>
