<template>
  <div class="is-flex" style="height:100vh;">
    <div class="tile is-justify-content-center" style="margin:auto;">
      <div
        class="box has-background-light"
        style="max-width: 90%;margin: auto;"
      >
        <div v-if="error" class="has-text-danger-dark">
          {{ error }}
        </div>
        <form action="#" @submit.prevent="submit">
          <div>
            <b-field label="Email">
              <b-input
                id="email"
                type="email"
                class="has-background-white-ter"
                name="email"
                required
                autofocus
                v-model="form.email"
              >
              </b-input>
            </b-field>
          </div>
          <div>
            <b-field label="Password">
              <b-input
                id="password"
                type="password"
                class="has-background-white-ter"
                name="password"
                required
                v-model="form.password"
                password-reveal
              >
              </b-input>
            </b-field>
          </div>
          <b-button type="is-primary" native-type="submit" class="mt-4"
            >Login</b-button
          >
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      error: null,
    };
  },
  methods: {
    submit() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(() => {
          this.$router.replace({ name: "Home" });
        })
        .catch((err) => {
          switch (err.code) {
            case "auth/user-not-found":
              this.error = "User Email Not Found";
              break;
            case "auth/wrong-password":
              this.error = "Password is incorrect";
              break;
            default:
              break;
          }
        });
    },
  },
};
</script>
